import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import '../components/all.sass'

export default function EmbeddedSoftwareDevelopmentPlanTemplate({
  data,
  pageContext,
}) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath} tableOfContents="true">
      <section className="section sectionbody">
        <h2 className="title is-3" id="1">
          1 Identification
        </h2>
        <p>
          This document describes the software development plan for device
          firmware. This plan addresses the following:
        </p>
        <ol>
          <li>
            The activities to be used in the development of the software system;
          </li>
          <li>The deliverables of each activity;</li>
          <li>
            Traceability between system requirements, software requirements,
            software system testing, and risk control measures implemented in
            software;
          </li>
          <li>
            Software configuration and change management, including SOUP
            configuration items and software used to support development; and
          </li>
          <li>
            Software problem resolution for handling problems detected in
            software products, deliverables, and activities at each stage of the
            life cycle.
          </li>
        </ol>
      </section>

      <section className="section sectionbody sectionbody">
        <h2 className="title is-3" id="2">
          2 Definitions
        </h2>
        <p>
          Words in all capitals are defined in IEC 62304. These definitions are
          not recopied within this document.
        </p>
      </section>

      <section className="section sectionbody">
        <div>
          <h2 className="title is-3" id="3">
            3 Software Safety Classification
          </h2>
          <p>
            If the answers to all of the questions in Tables 1 and 2 above are
            No, the FDA Level of Concern is Minor and the 62304 Software Safety
            Classification is Class A. The software safety classes defined based
            on severity as follows:
          </p>
          <blockquote>
            <p>
              {' '}
              Minor Concern / Class A: No injury or damage to health is possible
            </p>
            <p>Moderate Concern / Class B: Non-SERIOUS INJURY is possible</p>
            <p>Major Concern / Class C: Death or SERIOUS INJURY is possible</p>
          </blockquote>

          <h3 className="title is-5" id="3.1">
            3.1 Table 1 - Major Level of Concern / Class C
          </h3>
          <table className="table is-striped is-narrow is-hoverable">
            <thead>
              <th>
                {' '}
                Table 1 - If the answer to any one question below is Yes, the
                Level of Concern for the Software Device is likely to be Major.
              </th>
              <th className="has-text-white">Yes/No</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  1. Does the Software Device qualify as Blood Establishment
                  Computer Software?
                </td>
                <td>{frontmatter.bloodEstablishmentComputerSoftware}</td>
              </tr>
              <tr>
                <td>
                  2. Is the Software Device intended to be used in combination
                  with a drug or biologic?
                </td>
                <td>{frontmatter.usedInCombinationWithDrugOrBiologic}</td>
              </tr>
              <tr>
                <td>
                  3. Is the Software Device an accessory to a medical device
                  that has a Major Level of Concern / or device that could cause
                  permanent damage or death to patient?
                </td>
                <td>{frontmatter.canCausePermanentDamage}</td>
              </tr>
              <tr>
                <td>
                  Prior to mitigation of hazards, could a failure of the
                  Software Device result in death or serious injury, either to a
                  patient or to a user of the device? Examples of this include
                  the following:
                </td>
                <td>{frontmatter.canCauseDeath}</td>
              </tr>
              <tr>
                <td>
                  a. Does the Software Device control a life supporting or life
                  sustaining function?
                </td>
                <td>{frontmatter.lifeSupportFunction}</td>
              </tr>
              <tr>
                <td>
                  b. Does the Software Device control the delivery of
                  potentially harmful energy that could result in death or
                  serious injury, such as radiation treatment systems,
                  defibrillators, and ablation generators?
                </td>
                <td>{frontmatter.harmfulEnergy}</td>
              </tr>
              <tr>
                <td>
                  c. Does the Software Device control the delivery of treatment
                  or therapy such that an error or malfunction could result in
                  death or serious injury?
                </td>
                <td>{frontmatter.malfunctionDeath}</td>
              </tr>
              <tr>
                <td>
                  d. Does the Software Device provide diagnostic information
                  that directly drives a decision regarding treatment or
                  therapy, such that if misapplied it could result in serious
                  injury or death?
                </td>
                <td>{frontmatter.treatmentDeath}</td>
              </tr>
              <tr>
                <td>
                  e. Does the Software Device provide vital signs monitoring and
                  alarms for potentially life threatening situations in which
                  medical intervention is necessary?
                </td>
                <td>{frontmatter.medicalIntervention}</td>
              </tr>
            </tbody>
          </table>

          <h3 className="title is-5" id="3.2">
            3.2 Table 2 - Moderate Level of Concern / Class B
          </h3>

          <table className="table is-striped">
            <thead>
              <th>
                Table 2 - If the Software Device is not Major Level of Concern
                and the answer to any one question below is Yes, the Level of
                Concern is likely to be Moderate.
              </th>
              <th>Yes/No</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  1. Is the Software Device an accessory to a medical device
                  that has a Moderate Level of Concern / could harm patient?
                </td>
                <td>{frontmatter.couldHarmPatient}</td>
              </tr>
              <tr>
                <td>
                  2. Prior to mitigation of hazards, could a failure of the
                  Software Device result in Minor Injury, either to a patient or
                  to a user of the device?
                </td>
                <td>{frontmatter.minorInjury}</td>
              </tr>
              <tr>
                <td>
                  3. Could a malfunction of, or a latent design flaw in, the
                  Software Device lead to an erroneous diagnosis or a delay in
                  delivery of appropriate medical care that would likely lead to
                  Minor Injury?
                </td>
                <td>{frontmatter.erroneousDiagnosisOrDeliveryDelay}</td>
              </tr>
            </tbody>
          </table>

          <div className="block">
            <h3 className="title is-5" id="3.3">
              3.3 Classification Assessment
            </h3>
            <p>Enter Description of Device Activity and potential Hazards</p>
            <p>
              {' '}
              Information on inherent safety of device and why potential hazards
              to not pose a risk to patient or at what level the risk is and why
              it is acceptable.
            </p>
            <blockquote>
              <p>
                FDA Software level of Concern ={' '}
                {frontmatter.FDASoftwareLevelOfConcern}{' '}
              </p>
              <p>
                EN 62304:2006 Software Safety Classification ={' '}
                {frontmatter.SoftwareSafetyClassification}{' '}
              </p>
            </blockquote>
          </div>
        </div>
      </section>

      <section className="section sectionbody">
        <div className="">
          <div className="block">
            <h2 className="title is-3" id="4">
              4 Software Development Process
            </h2>
            <p>
              The following ACTIVITIES will be used to implement the software
              development PROCESS for this product. The ACTIVITIES will be
              executed in sequential order, with earlier ACTIVITIES being
              revisited as needed when issues are encountered. A review of the
              issue discovered will be used to determine which ACTIVITIES need
              to be revisited.
            </p>
          </div>

          <div className="block">
            <h3 className="title is-5" id="4.1">
              4.1 Software Specification ACTIVITY
            </h3>

            <p>
              This ACTIVITY takes the SYSTEM requirements as input and produces
              a list of specifications and verification tests as DELIVERABLE.
              Specifications prescribing RISK CONTROL measures shall be clearly
              identified. Included in the DELIVERABLE is the acceptance criteria
              for each verification test. The output is documented in a
              spreadsheet that is tracked in the document management system.
              Completion of this ACTIVITY requires review and approval of the
              DELIVERABLE in the document management system.
            </p>
          </div>

          <div className="block">
            <h3 className="title is-5" id="4.2">
              4.2 Software Design ACTIVITY
            </h3>
            <p>
              This ACTIVITY takes the software specifications as input and
              produces a state diagram and software elements diagram as
              DELIVERABLES. These DELIVERABLES are documented and tracked in the
              document management system. Completion of this ACTIVITY requires
              review and approval of the DELIVERABLES in the document management
              system.
            </p>
          </div>

          <div className="block">
            <h3 className="title is-5">4.3 Software Implementation ACTIVITY</h3>
            <p>
              This ACTIVITY takes the outputs of the Specification and Design
              ACTIVITIES as input and produces the software binaries and unit
              tests as DELIVERABLES. This ACTIVITY runs concurrently with the
              Verification ACTIVITY, and requires completion of both ACTIVITIES
              simultaneously. Upon completion of the Verification ACTIVITY, the
              DELIVERABLES of this ACTIVITY are uploaded to the document
              management system, reviewed, and approved.{' '}
            </p>
          </div>

          <div className="block">
            <h3 className="title is-5" id="4.4">
              4.4 Software Verification ACTIVITY
            </h3>
            <p>
              This ACTIVITY takes the DELIVERABLES of the Specification and
              Implementation ACTIVITIES as input and produces a verification
              test report as DELIVERABLE. Verification testing is performed for
              the entire SOFTWARE SYSTEM, with acceptance criteria previously
              established in the specification ACTIVITY. This DELIVERABLE is
              documented and tracked in the document management system.
              Completion of this ACTIVITY requires review and approval of the
              DELIVERABLE in the document management system.{' '}
            </p>
          </div>

          <div className="block">
            <h3 className="title is-5" id="4.5">
              4.5 Software Release ACTIVITY
            </h3>
            <p>
              This ACTIVITY takes the specifications and software binaries as
              input, publishes them to the customer deployment tools, and
              produces release notes and user documentation as DELIVERABLES.
              These outputs are documented and tracked in the document
              management system. Completion of this ACTIVITY requires review and
              approval of the output documentation in the document management
              system.
            </p>
          </div>
        </div>
      </section>
      <section className="section sectionbody">
        <h2 className="title is-3" id="5">
          5 Software Development Tools
        </h2>
        <p>
          The following tools will be used to support implementation of the
          software development PROCESS described above. [This section is
          required for Class B and Class C software.]
        </p>

        <div className="block">
          <h3 className="title is-5" id="5.1">
            5.1 Documentation Management
          </h3>
          <p>
            Documentation generated by the software development PROCESS will be
            managed according to the TZ Medical documentation management
            process.
          </p>
        </div>

        <div className="block">
          <h3 className="title is-5" id="5.2">
            5.2 Software Design
          </h3>
          <p>
            Software design will be created graphically using Google Drawings.
            The outputs will be downloaded as PDF files and managed using the
            document management system.
          </p>
        </div>

        <div className="block">
          <h3 className="title is-5" id="5.3">
            5.3 Coding and automated tests
          </h3>
          <p>
            Source code will be written and compiled using Atmel Studio. Unit
            tests will be executed on real hardware using an Atmel sam-ice and
            the Segger J Link software tools.
          </p>
        </div>

        <div className="block">
          <h3 className="title is-5" id="5.4">
            5.4 Configuration and Change Management
          </h3>
          <p>
            In-process software implementation changes will be tracked using git
            tools connected to repositories created on BitBucket servers. This
            will also be used for all SOUP used in the software development
            PROCESS. Verification documentation will correlate to the version
            tracked in git, providing pre-verification configuration management.
            Binaries of approved configurations will solely be managed through
            the document management system. Mapping of software configurations
            to physical products will be managed through DMR files in the
            document management system.
          </p>
        </div>

        <div className="block">
          <h3 className="title is-5" id="5.5">
            5.5 Obsolescence Management
          </h3>
          <p>
            As new versions of development tools are made available, this
            project will update to the latest version and rerun all test
            procedures. These updates can be timed to coincide with a planned
            configuration release; a dedicated tool update release is not
            required.
          </p>
        </div>
      </section>
      <section className="section sectionbody">
        <div className="">
          <h2 className="title is-3" id="6">
            6 Coding Standard
          </h2>
          <p>
            The source code written entirely by TZ medical will adhere to the
            coding standards outlined in document number 13400 “Embedded Coding
            Standards”. Source code supplied by outside vendors shall be
            thoroughly reviewed and tested, but need not comply with this coding
            standard. If significant changes are made to external source code,
            the changes should comply with this coding standard.
          </p>
        </div>
      </section>
      <section className="section sectionbody">
        <div className="container">
          <h2 className="title is-3" id="7">
            7 Verification Plan
          </h2>
          <p>
            Each ACTIVITY in the software development PROCESS shall end with a
            milestone review with representative participation from engineering,
            QA, and an independent reviewer. This review will evaluate the
            output of the ACTIVITY for coherence, completeness, and accuracy.
          </p>
        </div>
      </section>
      <section className="section sectionbody">
        <div className="container">
          <h2 className="title is-3" id="8">
            8 Responsibilities
          </h2>
          <p>
            The software development team will be comprised of the following
            members:
          </p>

          <h3 className="title is-5" id="8.1">
            8.1 Project Manager
          </h3>
          <p>
            The project manager shall be responsible for specification and test
            development, project scheduling, managing code implementation,
            software integration, and control documentation generation.
          </p>

          <h3 className="title is-5" id="8.2">
            8.2 Test Engineer
          </h3>
          <p>
            The test engineer shall be responsible for performing all
            verification tests and generating verification test reports.
          </p>

          <h3 className="title is-5" id="8.3">
            8.3 Quality Management
          </h3>
          <p>
            Quality Management shall manage change control activities, oversee
            milestone reviews, and ensure that applicable standards are current
            and available.
          </p>

          <h3 className="title is-5" id="8.4">
            8.4 Independent Reviewer{' '}
          </h3>
          <p>
            The independent reviewer shall participate in milestone reviews.
          </p>

          <h3 className="title is-5" id="8.5">
            8.5 Additional Support Persons{' '}
          </h3>
          <p>
            Additional support persons shall be added to the team as needed to
            participate in milestone reviews, assist with code implementation,
            and assist with document generation.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryUserNeedsAndValidationTests($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName

        bloodEstablishmentComputerSoftware
        usedInCombinationWithDrugOrBiologic
        canCausePermanentDamage
        canCauseDeath
        lifeSupportFunction
        harmfulEnergy
        malfunctionDeath
        treatmentDeath
        medicalIntervention

        couldHarmPatient
        minorInjury
        erroneousDiagnosisOrDeliveryDelay

        FDASoftwareLevelOfConcern
        SoftwareSafetyClassification
      }
    }
  }
`
